import Homepage from './components/homepage'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import NotFound from './components/NotFound'

const App = () => {
  return (
    <>
      <div className="site-wrapper">
        <BrowserRouter>
          <Routes>
            <Route path = "/" element= {< Homepage />}/>
            <Route path = '*' element= {< NotFound />}/>
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App
